import { render, staticRenderFns } from "./_Routes.vue?vue&type=template&id=1b0d9b8c&scoped=true"
import script from "./_Routes.vue?vue&type=script&lang=js"
export * from "./_Routes.vue?vue&type=script&lang=js"
import style0 from "./_Routes.vue?vue&type=style&index=0&id=1b0d9b8c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b0d9b8c",
  null
  
)

export default component.exports